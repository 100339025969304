import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar, Reviews } from '../../components';

import css from './ProfileListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;
  const [reviewScore, setReviewScore] = useState(0)
  const [isShowAllReviews, setShowAllReviews] = useState(false)

  useEffect(()=>{
    let reviewSum = 0;
    reviews.forEach(element => {
      reviewSum+=element.attributes.rating
    });
    if(reviews.length > 0) {
      setReviewScore((reviewSum/reviews.length).toFixed(1))
    }
  }, [reviews])

  const showAllReviews = () => {
    setShowAllReviews(true)
  }

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ProfileListingPage.reviewsError" />
    </h2>
  );

  return (
    <div className={css.reviewSectionContainer}>
      <div className={css.sectionReviews}>
        <h2 className={css.reviewsHeading}>
          <FormattedMessage id="ProfileListingPage.reviewsHeading" />
        </h2>
        <div className={css.reviewsSubheaderContainer}>
          <div className={css.reviewOverallRatingContainer}>
            <IconReviewStar className={css.overallRatingStar} />
            <p className={css.overallRating}>{reviewScore}</p>
          </div>
          <p className={css.reviewSectionSubheading}>
            <FormattedMessage
              id="ProfileListingPage.reviewsSubheading"
              values={{ count: reviews.length }}
            />
          </p>
        </div>
        {fetchReviewsError ? reviewsError : null}
        <Reviews reviews={isShowAllReviews ? reviews : reviews.slice(0,4)} />
        {!isShowAllReviews && reviews.length > 4 && <div onClick={showAllReviews} className={css.showMoreReivewsButton}>Show more reviews</div>}
      </div>
    </div>
  );
};

export default SectionReviews;
